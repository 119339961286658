var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex text-truncate py-75 px-1 px-md-2 rounded-8px w-100 gap-2",
    class: {
      'bg-gray ': _vm.showInfoFlight
    }
  }, [_vm.showInfoFlight ? _c('div', {
    staticClass: "flex-fill"
  }, [_vm.flightInfo.status ? _c('div', {
    staticClass: "pb-25 d-flex-between"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('reservation.flightsDetails.status')) + ": ")]), _c('span', {
    staticClass: "font-weight-bolder",
    class: _vm.flightInfo.status === 'HK' ? 'text-success' : ['HL', 'SC'].includes(_vm.flightInfo.status) ? 'text-danger' : _vm.flightInfo.status === 'KL' ? 'text-warning' : ''
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.status) + " ")]), ['UC', 'HL', 'DS', 'NO', 'HX', 'AK', 'GK'].includes(_vm.flightInfo.status) ? _c('div', {
    staticClass: "text-danger fw-700"
  }, [_vm._v(" Lưu ý: Chưa có chỗ ")]) : _vm._e()]) : _vm._e(), _vm.flightInfo.bookingClass || _vm.flightInfo.groupClass ? _c('div', {
    staticClass: "pb-25 d-flex-between"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('reservation.flightsDetails.bookingClass')) + ": ")]), _vm.flightInfo.fareBasisCode ? _c('div', [_vm._v(" ("), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.groupClass || _vm.getFareRulesAirlineByBookingClass(_vm.flightInfo.airline, _vm.flightInfo.bookingClass).cabinName) + " ")]), _vm.flightInfo.bookingClass && (_vm.flightInfo.groupClass || _vm.getFareRulesAirlineByBookingClass(_vm.flightInfo.airline, _vm.flightInfo.bookingClass).cabinName) ? _c('span', [_vm._v(" | ")]) : _vm._e(), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.bookingClass) + " ")]), _c('span', {
    staticClass: "fw-600 text-danger ml-25"
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.fareBasisCode) + " ")]), _vm._v(" ) ")]) : _c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.groupClass || _vm.getFareRulesAirlineByBookingClass(_vm.flightInfo.airline, _vm.flightInfo.bookingClass).cabinName) + " ")]), _vm.flightInfo.bookingClass && (_vm.flightInfo.groupClass || _vm.getFareRulesAirlineByBookingClass(_vm.flightInfo.airline, _vm.flightInfo.bookingClass).cabinName) ? _c('span', [_vm._v(" | ")]) : _vm._e(), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.bookingClass) + " ")])])]) : _vm._e(), _vm.reservationsData.supplierPNRs && _vm.reservationsData.supplierPNRs.length ? _c('div', {
    staticClass: "pb-25 d-flex-between"
  }, [_c('small', [_vm._v("Air PNR: ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.resolveAirPnr(_vm.reservationsData.supplierPNRs, _vm.flightInfo.airline, {
    showInEticket: false
  })) + " ")])]) : _vm._e(), _vm.reservationsData.isDomestic && (_vm.flightInfo.bookingClass || _vm.flightInfo.groupClass) && _vm.reservationsData.source !== 'QH' || _vm.reservationsData.isDomestic && _vm.flightInfo.groupClass && _vm.reservationsData.source === 'QH' ? _c('div', {
    staticClass: "pb-25 d-flex-between",
    attrs: {
      "id": "rule-refund-".concat(_vm.index)
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.$t("reservation.".concat(_vm.reservationsData.source === 'VJ' ? 'refundTicketVJ' : 'refundTicket'))) + ": ")]), _c('BBadge', {
    staticClass: "font-weight-bold text-wrap",
    attrs: {
      "variant": ['', 'NONE', false].includes(_vm.getFareRulesAirlineByBookingClass(_vm.flightInfo.airline, ['VJ', 'QH'].includes(_vm.flightInfo.airline) ? _vm.flightInfo.groupClass : ['VU'].includes(_vm.flightInfo.airline) ? _vm.flightInfo.fareType : _vm.flightInfo.bookingClass).refund) ? 'danger' : 'info'
    }
  }, [_vm._v(" " + _vm._s(['', 'NONE', false].includes(_vm.getFareRulesAirlineByBookingClass(_vm.flightInfo.airline, ['VJ', 'QH'].includes(_vm.flightInfo.airline) ? _vm.flightInfo.groupClass : ['VU'].includes(_vm.flightInfo.airline) ? _vm.flightInfo.fareType : _vm.flightInfo.bookingClass).refund) ? _vm.$t('reservation.disallowRefund') : _vm.$t('reservation.allowRefund')) + " " + _vm._s("".concat(_vm.reservationsData.source === 'VJ' ? _vm.$t('reservation.identification') : '')) + " ")])], 1) : _vm._e(), _vm.flightInfo.mileage ? _c('div', {
    staticClass: "pb-25 d-flex-between"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('reservation.flightsDetails.distance')) + ": ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(Number(_vm.flightInfo.mileage)))])]) : _vm._e(), (_vm.reservationsData.source !== 'QH' || _vm.reservationsData.source === 'QH' && _vm.flightInfo && _vm.flightInfo.groupClass) && _vm.getBaggageInBookingDetail(_vm.flightInfo, _vm.reservationsData).carryBag ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text-wrap pb-25 d-flex-between",
    attrs: {
      "title": _vm.getBaggageInBookingDetail(_vm.flightInfo, _vm.reservationsData).carryBag
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('ticket.flightsInfo.handBaggage')) + ": ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.getBaggageInBookingDetail(_vm.flightInfo, _vm.reservationsData).carryBag) + " ")])]) : _vm._e(), _vm.reservationsData.source !== 'QH' || _vm.reservationsData.source === 'QH' && _vm.flightInfo && _vm.flightInfo.groupClass ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text-wrap d-flex-between",
    attrs: {
      "title": _vm.getBaggageInBookingDetail(_vm.flightInfo, _vm.reservationsData).checkinBag
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('ticket.flightsInfo.freeBaggage')) + ": ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.getBaggageInBookingDetail(_vm.flightInfo, _vm.reservationsData).checkinBag) + " ")])]) : _vm._e()]) : _c('div', {
    staticClass: "flex-fill"
  }), _c('div', [!_vm.hideFeature ? _c('BButton', {
    staticClass: "p-25 rounded-circle",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showInfoFlight = !_vm.showInfoFlight;
      }
    }
  }, [_vm.showInfoFlight ? _c('feather-icon', {
    attrs: {
      "icon": "EyeIcon",
      "size": "16"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "EyeOffIcon",
      "size": "16"
    }
  })], 1) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }